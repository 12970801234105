<template>
  <div
    class="row"
    style="background:#fff; border-radius: 10px; box-shadow: 0.5px 0.866px 4px 0px rgba(2, 8, 2, 0.2); padding-bottom: 25px;"
  >
    <div class="col-md-12 col-sm-12 mt-1">
      <div class="row mt-1">
      </div>
      <div class="row mt-2"><!-- Tüm Kullanıcılar -->
      <!-- MYE ASSESSMENTS -->
          <!-- <div class="col-md-6 mt-1" v-if="Number(this.grade) >= 5 && Number(this.grade) <= 10">
            kaldırıldı href="https://app.newsomatic.net/login"
              <a :href="`https://www.myeduassessment.com/#/login${querystingtext}`" target="blank">
                <div :class="$style.divPlatform">
                  <div :class="[$style.divPlatformBtn, $style.divPlatformBtnAssessment]"></div>
                  kaldırıldı <div :class="$style.txtPlatform">CLASSROOM</div>
                </div>
              </a>
          </div> -->
        <!-- HLL -->
          <div class="col-md-6 mt-1" v-if="(Number(this.grade) >= 2 && Number(this.grade) <= 5) || findTeacGrade(2,5)">
            <!--href="https://app.newsomatic.net/login"--->
              <a @click="checkHll()" target="_blank">
                <div :class="$style.divPlatform">
                  <div :class="[$style.divPlatformBtn, $style.divPlatformBtnHLL]"></div>
                  <!-- <div :class="$style.txtPlatform">CLASSROOM</div> -->
                </div>
              </a>
          </div>
        <!-- NOM -->
          
          <div class="col-md-6 mt-1" v-if="(Number(this.grade) >= 6 && Number(this.grade) <= 8) || findTeacGrade(6,8)">
            <!--href="https://app.newsomatic.net/login"--->
              <a @click="nom()" target="_blank">
                <div :class="$style.divPlatform">
                  <div :class="[$style.divPlatformBtn, $style.divPlatformBtnNewsOMatic]"></div>
                  <!-- <div :class="$style.txtPlatform">CLASSROOM</div> -->
                </div>
              </a>
          </div>
          
          <!-- <div class="col-md-6 mt-1" v-if="showDeutschbesten()">
              <a :href="`https://www.deutschbesten.com/#/${querystingtext}`" target="_blank">
                <div :class="$style.divPlatform">
                  <div :class="[$style.divPlatformBtn, $style.divPlatformBtnDeutschbesten]"></div>
                </div>
              </a>
          </div> -->

          <!-- <div class="col-md-6 mt-1" v-if=" roleType==='student' && (Number(this.grade) >= 1 && Number(this.grade) <= 4)">
              <a @click="RLP()" target="_blank">
                <div :class="$style.divPlatform">
                  <div :class="[$style.divPlatformBtn, $style.divPlatformBtnRLP]"></div>
                  -- <div :class="$style.txtPlatform">CLASSROOM</div> --
                </div>
              </a>
          </div> -->
          <!-- <div class="col-md-6 mt-1" v-if=" roleType==='teacher' && ((Number(this.grade) >= 1 && Number(this.grade) <= 4) || findTeacGrade(2,5))">
              <a :href="`https://richmondlp.com/login`" target="blank">
                <div :class="$style.divPlatform">
                  <div :class="[$style.divPlatformBtn, $style.divPlatformBtnRLP]"></div>
                  -- <div :class="$style.txtPlatform">CLASSROOM</div> --
                </div>
              </a>
          </div> -->

          <div class="col-md-12 mt-1" v-if="grade === '103' || findTeacGrade(103,103)">
            <a :href="`https://englishplaybox.net/#/${amkepbdemo1}`" target="_blank">
              <div :class="$style.divPlatform" >
                <div :class="[$style.divPlatformBtnModal, $style.divPlatformBtnEnglishPlayBox]"></div>
                <div :class="$style.txtPlatform">ENGLISH PLAYBOX LEVEL 1</div>
              </div>
            </a>
          </div>
          <div class="col-md-12 mt-1" v-else-if="grade === '104' || findTeacGrade(104,104)">
            <a :href="`https://englishplaybox.net/#/${amkepbdemo2}`" target="_blank">
              <div :class="$style.divPlatform" >
                <div :class="[$style.divPlatformBtnModal, $style.divPlatformBtnEnglishPlayBox]"></div>
                <div :class="$style.txtPlatform">ENGLISH PLAYBOX LEVEL 2</div>
              </div>
            </a>
          </div>
          <div class="col-md-12 mt-1" v-else-if="grade === '105' || findTeacGrade(104,105)">
            <a :href="`https://englishplaybox.net/#/${amkepbdemo3}`" target="_blank">
                <div :class="$style.divPlatform">
                <div :class="[$style.divPlatformBtnModal, $style.divPlatformBtnEnglishPlayBox]"></div>
                <div :class="$style.txtPlatform">ENGLISH PLAYBOX LEVEL 3</div>
              </div>
            </a>
          </div>
              
        
      </div>
    </div>
  </div>
</template>

<script>
import swal from 'sweetalert2';
import { mapState, mapActions } from "vuex";
import services from "@/services/user";
// import InnerLoading from "@/components/InnerLoading";
import CryptoJS from 'crypto-js';

export default {
  name: "platforms",
  data() {
    return {
      teacherGrades:[],
      grade: "",
      level: "",
      roleType: "",
      campusId: null,
      querystingtext: "",
      kwquerystingtext: "",
      hexaquerystingtext: "",
      zoom: "",
      checkTime: true,
      logCompassSchool: "",
      logCompassUser: "",
      logCompassPass: "",
      ues_digital_code: "",
      ues_digital_code_check: "",
      compass_ready: false,
      amkepbdemo1: null,
      amkepbdemo2: null,
      amkepbdemo3: null,
    };
  },
  computed: {
    ...mapState(["userDetails", "username", "password"]),
    levelName() {
      return this.$store.state.userDetails.organization.find(
        (x) => x.type === "level"
      ).name;
    },
  },
  mounted() {},
  beforeMount() {
    this.setData();
    if (this.userDetails.uesdigital_code != null) {
      this.ues_digital_code = this.userDetails.uesdigital_code;
      this.logCompassUser =
        this.userDetails.compass_username != null
          ? atob(this.userDetails.compass_username)
          : "";
      this.logCompassPass =
        this.userDetails.compass_password != null
          ? atob(this.userDetails.compass_password)
          : "";
      this.logCompassSchool = this.userDetails.compass_school;
    }
  },
  watch: {
    userDetails() {
      this.setData();
    },
    logCompassUser() {},
  },
  components: {
    // InnerLoading,
  },
  methods: {
    ...mapActions(["getUserDetails"]),
    async timeStamp() {
      const res = await services.getTimeStamp();
      if (this.title === "demo") return true;
      if (res.data.is_weekend) return false;
      // eslint-disable-next-line
      return res.data.data.split(" ")[1] > "08:00:00" &&
        res.data.data.split(" ")[1] < "17:00:00"
        ? true
        : false;
    },
    doSomethingOnHidden() {
      //do something
    },

    async setData() {
      console.log('userDetails',this.userDetails)
      if (this.userDetails) {
        this.level = this.userDetails.organization.find(
          (x) => x.type === "level"
        ).name;

        const teacherGrades = this.userDetails.organization.filter((x) => x.type === "grade")
        for (let key in teacherGrades){
          this.teacherGrades.push(teacherGrades[key].name.split("-")[0])
        }

        this.grade = this.userDetails.organization.find(
          (x) => x.type === "grade"
        )
          ? this.userDetails.organization
              .find((x) => x.type === "grade")
              .name.split("-")[0]
          : 0;
        this.main = this.userDetails.organization.find(
          (x) => x.type === "main"
        ).id;

        this.zoom = this.userDetails.organization.filter(
          (x) => x.type === "grade"
        );
        this.campusId = this.userDetails.organization.find(y => y.type === 'campus').id;
        this.roleType = this.userDetails.role_type;
        this.title = this.userDetails.title;
        this.querystingtext = `?username=${btoa(this.username)}&password=${btoa(
          this.password
        )}`;
        if (this.userDetails.lang === "en") {
          this.hexaquerystingtext = `?username=${btoa(
            "steve.jobs"
          )}&password=${btoa("090807")}`;
        } else {
          this.hexaquerystingtext = this.querystingtext;
        }
        this.checkTime = await this.timeStamp();
      }
      this.kwquerystingtext = `?username=${btoa(
        "uesnlkw_teacher"
      )}&password=${btoa("123456")}`;

      this.amkepbdemo1 = `?username=${btoa('epbdemo1')}&password=${btoa('123456')}`;
      this.amkepbdemo2 = `?username=${btoa('epbdemo2')}&password=${btoa('123456')}`;
      this.amkepbdemo3 = `?username=${btoa('epbdemo3')}&password=${btoa('123456')}`;
    },

    findTeacGrade(grade1, grade2){
        const grade = this.teacherGrades.filter((x) => (x >= grade1 && x <= grade2))
        if (grade.length > 0) return true; else return false;
    },

    showDeutschbesten(){
      if (this.campusId == 68129 ){
        if (this.roleType == 'teacher'){
          if (this.findTeacGrade(1,7) == true || this.findTeacGrade(9,11) == true) return true; else return false;
        } else if (this.grade == 1 || this.grade == 2 || this.grade == 3 || this.grade == 4 || this.grade == 5 ||
        this.grade == 6 || this.grade == 7 || this.grade == 9 || this.grade == 10 || this.grade == 11 ){
          return true
        } else return false
      } else if (this.campusId == 68127 ){
        if (this.roleType == 'teacher'){
          if (this.findTeacGrade(4,7) == true) return true; else return false;
        } else if (this.grade == 4 || this.grade == 5 || this.grade == 6 || this.grade == 7 ){
          return true
        } else return false
      } else if (this.campusId == 68137 || this.campusId == 68138 ){
        if (this.roleType == 'teacher'){
          if (this.findTeacGrade(5,7) == true || this.findTeacGrade(9,10) == true) return true; else return false;
        } else if (this.grade == 5 || this.grade == 6 || this.grade == 7 || this.grade == 9 || this.grade == 10 ){
          return true
        } else return false
      } else return false;
    },

    async checkHll() {
      try {
        this.ready = false;
        // const userDetails = await userService.getStudentDetails();
        const userDetails = await this.userDetails;

        const userId = userDetails.user_id;
        const firstName = userDetails.first_name;
        const lastName = userDetails.last_name;
        // const className = userDetails.organization.find(x => x.type === 'grade').name;
        const userType = userDetails.role_type;
        // const campusId = userDetails.organization.find(y => y.type === 'campus').id;
        const key = userDetails.organization.find(y => y.type === 'campus').hll_company_code;
        // console.log('key', key);
        // const key = obj.find(x => x.schoolId === campusId).companyKey;

        if(userDetails.role_type === "student"){
          const className = encodeURIComponent(userDetails.organization.find(x => x.type === 'grade').name);
          window.go_hll(userId, userId, firstName, lastName, className, userType, key);
        }else if(userDetails.role_type === "teacher"){
          const classes = [];
          await userDetails.organization.filter(x => x.type === 'grade').forEach((item) => {
            classes.push(encodeURIComponent(item.name))
          });
          window.go_hll(userId, userId, firstName, lastName, classes, userType, key);
        }
        setTimeout(() => {
          this.ready = true;
        }, 1000);
      } catch (error) {
        console.log('err', error);
        swal('Giriş Yapılamadı!', 'Bir Hata Oluştu', 'error');
        this.ready = true;
      }
    },
    // nom() {
    //     window.open('https://app.newsomatic.net/login', '_blank');
    // },

    nom() {
      // console.log('sdsds')
      try {
        this.ready = false;
        const secretKey = "Pbt6DND9k5tu7Tdj284-1szLNPEXwcVznFMN4G@UdQtZ7.GEU7!BPy";
        const userData = {}
        if (this.userDetails) {
          userData.school_id =  this.userDetails.organization.find(x => x.type === 'main').id;
          userData.school_name =  this.userDetails.organization.find(x => x.type === 'main').name;
          userData.campus_id =  this.userDetails.organization.find(x => x.type === 'campus').id;
          userData.campus_name = this.userDetails.organization.find(x => x.type === 'campus').name;
          userData.first_name = this.userDetails.first_name;
          userData.last_name = this.userDetails.last_name;
          userData.username = this.userDetails.email;
          userData.role_type = this.userDetails.role_type;
          userData.class = this.userDetails.organization.find(x => x.type === 'grade') ? this.userDetails.organization.find(x => x.type === 'grade').name.split('-')[0] : 0;
          userData.branch = this.userDetails.organization.find(x => x.type === 'grade') ? this.userDetails.organization.find(x => x.type === 'grade').name.split('-')[1] : '';
        }
        //  console.log(userData)

        const textJson = JSON.stringify(userData);
        const uriText = decodeURIComponent(textJson)
        const ciphertext = CryptoJS.AES.encrypt(uriText, secretKey).toString();
        this.ready = true;
        window.open('https://service.ueslms.com/#/nom/?sso=' + btoa(ciphertext));
        

      } catch (error) {
        console.log('err', error);
        swal('Giriş Yapılamadı!', 'Bir Hata Oluştu', 'error');
        this.ready = true;
      }
    },

    RLP() {
      // console.log('sdsds')
      try {
        this.ready = false;
        const secretKey = "VswFYGzh2k45N7Q8R9@TBuCVexFY.G2J3K4N6p7Q%9SATB-VdWeXFZHJ3m5N";
        const userData = {}
        if (this.userDetails) {
          userData.username = this.userDetails.email;
          userData.first_name = this.userDetails.first_name;
          userData.last_name = this.userDetails.last_name;
          userData.role_type = this.userDetails.role_type;
          userData.school_id =  this.userDetails.organization.find(x => x.type === 'main').id;
          userData.campus_id =  this.userDetails.organization.find(x => x.type === 'campus').id;
          userData.campus_name = this.userDetails.organization.find(x => x.type === 'campus').name;
          userData.class = this.userDetails.organization.find(x => x.type === 'grade') ? this.userDetails.organization.find(x => x.type === 'grade').name.split('-')[0] : 0;
          userData.branch = this.userDetails.organization.find(x => x.type === 'grade') ? this.userDetails.organization.find(x => x.type === 'grade').name.split('-')[1] : '';
          userData.user_id = this.userDetails.user_id;
        }
        //  console.log(userData)

        const textJson = JSON.stringify(userData);
        const uriText = decodeURIComponent(textJson)
        const ciphertext = CryptoJS.AES.encrypt(uriText, secretKey).toString();
        this.ready = true;
        window.open('https://service.ueslms.com/#/rlp/?sso=' + btoa(ciphertext));
        

      } catch (error) {
        console.log('err', error);
        swal('Giriş Yapılamadı!', 'Bir Hata Oluştu', 'error');
        this.ready = true;
      }
    },
  },
};
</script>

<style src="./style.less" lang="less" module />

<style scoped>
a {
  text-decoration: none;
  color: black;
}
</style>
